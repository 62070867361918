import { from, to } from '@ev/search-modules-components';
import { styled as muiStyled } from '@mui/material';
import { css, styled } from 'styled-components';

export const StyledContainer = muiStyled('main')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(0, 0, 5, 0),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 0, 5, 0),
  },
  margin: 'auto',
  maxWidth: '1440px',
}));

export const StyledPageContainer = styled.div<{
  $isHeaderFixed: boolean;
  $isLegacyHeader: boolean;
}>`
  ${({ $isLegacyHeader }) => css`
    --header-height: ${$isLegacyHeader ? '71px' : '57px'};

    @media screen and (${from.tabletPortrait}) {
      --header-height: ${$isLegacyHeader ? '104.08px' : '77px'};
    }
    @media screen and (${from.tabletLandscape}) {
      --header-height: ${$isLegacyHeader ? '127px' : '135px'};
    }
  `}
  > .sp-header,
  #header {
    @media screen and (${from.tabletLandscape}) {
      // required for the submenus in the header to be displayed correctly
      position: relative !important;
    }
    @media screen and (${to.tabletLandscape}) {
      ${({ $isHeaderFixed, $isLegacyHeader }) =>
        $isHeaderFixed &&
        !$isLegacyHeader &&
        css`
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 101;
        `}
    }
  }
`;
